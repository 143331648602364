import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConsoleLogger, LogIcon } from '@dotglitch/ngx-common';

import { Tenant } from '../components/tenantselector/tenantselector.component';
import { User_v1 } from '../../dto/eom/user-v1';
import { UserProfile } from 'src/app/types/user';
import { Persona } from 'src/dto/eom/persona';
import { Fetch } from 'src/app/services/fetch.service';

const { log, warn, err } = ConsoleLogger("Tenant", "#ffb74d");

/**
 * Override
 */
class Users extends BehaviorSubject<User_v1[]> {

    public emailMap: { [key: string]: User_v1 };
    public idMap: { [key: number]: User_v1 };

    constructor() {
        super([]);

        this.subscribe(users => {
            this.emailMap = {};
            this.idMap = {};

            // Map all of the users based on their ID/Email
            users.forEach(u => {
                this.emailMap[u.email] = u;
                this.idMap[u.id] = u;
            })
        })
    }

    public get(userId: number) {
        return this.idMap[userId];
    }
}

@Injectable({
    providedIn: 'root'
})
export class TenantService extends BehaviorSubject<Tenant> {

    /**
     * List of users in the selected tenant.
     */
    public users = new Users();

    /**
     * State if the tenant is locked
     * (user cannot navigate out of the tenant)
     */
    public isTenantLocked = new BehaviorSubject(false);

    public readonly demoTenant = {
        "https://localhost:4200": "DEMO-DEV",
        "https://dev.elevate.dynatrace.com": "DEMO-DEV",
        "https://stage.elevate.dynatrace.com": "DEMO-STAGE",
        "https://elevate.dynatrace.com": "DEMO",
        "https://beta.elevate.dynatrace.com": "DEMO",
        "https://uk.elevate.dynatrace.com": "DEMO-UK",
        "https://beta-uk.elevate.dynatrace.com": "DEMO-UK",
        "https://emea.elevate.dynatrace.com": "DEMO-EMEA",
        "https://beta-emea.elevate.dynatrace.com": "DEMO-EMEA",
        "https://apac.elevate.dynatrace.com": "DEMO-APAC",
        "https://beta-apac.elevate.dynatrace.com": "DEMO-APAC"
    }[location.origin];

    /**
     * List of persona configurations
     */
    private personaDetails: {
        persona_description: string,
        persona_id: string,
        persona_image: string,
        persona_landingpage: string,
        persona_label: string,
        persona_topfeatures: string,
        persona_type: string,
        persona_video_url: string,
    }[] = [];

    constructor(
        private fetch: Fetch
    ) {
        super(null);

        this.subscribe(tenant => {
            if (!tenant) return;

            log(LogIcon.security, `Changing tenant to ${tenant.es_tenant_name}`);
            localStorage['es-tenant'] = localStorage['ES-Tenant'] = window['ES-Tenant'] = window["es-tenant"] = tenant.es_tenant_name;
            if (window.router?.urlData) {
                window.router.urlData['es-tenant'] = tenant.es_tenant_name;
            }

            // Clear any old values
            this.users.next([]);
            this.personaDetails = [];

            // Fetch data for tenant
            this.refreshUsers();

            this.fetch.get<any>("/api/eda/v1.0/data?name=persona_get&persona=ALL")
                .then((result) => this.personaDetails = result)
        })

        window.tenant = this;
    }

    public refreshUsers() {
        // @ts-ignore Bart needs to fix the typing here
        User_v1.select(`((status eq null) or (status ne 'deleted'))`)
            .then(users => this.users.next(users));
    }

    public getPersonaDetail(id: Persona): any {
        const result = this.personaDetails.find(({ persona_id, persona_label }) => persona_id === id || persona_label === id);
        return result?.persona_label || id || "(No persona)";
    }

    /**
     * Initialize the component via the loaded user.
     * Because UserProfile needs to DI inject this service,
     * we weakly bind this method through the root.component.
     *
     */
    initialize(user: UserProfile, tenantName?: string) {
        /**
         * 1. take tenant from URL
         * 2. take tenant from cookie
         * 3. take tenant[0] from user.
         */
        let tenant: Tenant = null;

        if (tenantName) {
            tenantName = tenantName.toUpperCase().trim();
            tenant = user.tenants.find(a => a.es_tenant_name.toUpperCase() == tenantName);
        }

        // check URL for tenant, ensure user still has access.
        if (!tenant) {
            // @ts-ignore
            const client = location.href.match(/(es-tenant|client)=(?<client>[^&]+)/i)?.groups?.client;
            tenant = user.tenants.find(a => a.es_tenant_name == client);
        }

        // Check localStorage for tenant
        if (!tenant) {
            const client = localStorage['ES-Tenant'];
            tenant = user.tenants.find(a => a.es_tenant_name == client);
        }

        // Default to whatever the user has.
        // Shouldn't be empty at this point.
        if (!tenant) {
            tenant = user.tenants[0];
        }

        log(LogIcon.security, `Initializing with tenant ${tenant.es_tenant_name}`);

        this.next(tenant);
    }
}
